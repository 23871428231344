// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biografia-js": () => import("./../../../src/pages/biografia.js" /* webpackChunkName: "component---src-pages-biografia-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-fotos-js": () => import("./../../../src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-poesias-js": () => import("./../../../src/pages/poesias.js" /* webpackChunkName: "component---src-pages-poesias-js" */),
  "component---src-pages-proposicoes-js": () => import("./../../../src/pages/proposicoes.js" /* webpackChunkName: "component---src-pages-proposicoes-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-poesia-js": () => import("./../../../src/templates/poesia.js" /* webpackChunkName: "component---src-templates-poesia-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

